import { default as DatenschutzF1jVDB2FNUMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/Datenschutz.vue?macro=true";
import { default as ImpressumOY4EFlFa3dMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/Impressum.vue?macro=true";
import { default as indexzXPXWw7TOBMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/index.vue?macro=true";
import { default as indexaW0MohKteVMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/[block]/[id]/index.vue?macro=true";
import { default as indexOqfX6L2noEMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/[block]/index.vue?macro=true";
import { default as index7CtcIwLfktMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/index.vue?macro=true";
import { default as _91slug_93_46htmlFyOGANzrDdMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/[slug].html.vue?macro=true";
import { default as _91provider_93qjXPGcW2XhMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/[provider].vue?macro=true";
import { default as _91provider_931MCtqQEfvbMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/confirmation/[provider].vue?macro=true";
import { default as indexzz1f3UoSQqMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/confirmation/index.vue?macro=true";
import { default as index1co2pnC5ECMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/index.vue?macro=true";
import { default as reservationEL7FytxwqKMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/reservation.vue?macro=true";
import { default as sucheXzbG68SpR7Meta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/suche.vue?macro=true";
import { default as _91webcode_93_45_91slug_93_46htmlwDFPOJe7xgMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/[productType]/[webcode]-[slug].html.vue?macro=true";
import { default as indexMUM7Z4hjf3Meta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/[productType]/index.vue?macro=true";
import { default as indexuvzkmoyql9Meta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/index.vue?macro=true";
import { default as indexWan48lElNjMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/index.vue?macro=true";
import { default as _91slug_93_46htmlNugt7GprEWMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[slug].html.vue?macro=true";
import { default as wishlistspmwo7ugN8Meta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/wishlist.vue?macro=true";
import { default as debugwMBdxZ8jSxMeta } from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/test/debug.vue?macro=true";
export default [
  {
    name: "app-Datenschutz",
    path: "/app/Datenschutz",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/Datenschutz.vue")
  },
  {
    name: "app-Impressum",
    path: "/app/Impressum",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/Impressum.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: indexzXPXWw7TOBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/index.vue")
  },
  {
    name: "app-topic-block-id",
    path: "/app/topic/:block()/:id()",
    meta: indexaW0MohKteVMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/[block]/[id]/index.vue")
  },
  {
    name: "app-topic-block",
    path: "/app/topic/:block()",
    meta: indexOqfX6L2noEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/[block]/index.vue")
  },
  {
    name: "app-topic",
    path: "/app/topic",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/app/topic/index.vue")
  },
  {
    name: "shop-slug.html",
    path: "/shop/:slug().html",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/[slug].html.vue")
  },
  {
    name: "shop-checkout-provider",
    path: "/shop/checkout/:provider()",
    meta: _91provider_93qjXPGcW2XhMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/[provider].vue")
  },
  {
    name: "shop-checkout-confirmation-provider",
    path: "/shop/checkout/confirmation/:provider()",
    meta: _91provider_931MCtqQEfvbMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/confirmation/[provider].vue")
  },
  {
    name: "shop-checkout-confirmation",
    path: "/shop/checkout/confirmation",
    meta: indexzz1f3UoSQqMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/confirmation/index.vue")
  },
  {
    name: "shop-checkout",
    path: "/shop/checkout",
    meta: index1co2pnC5ECMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/index.vue")
  },
  {
    name: "shop-checkout-reservation",
    path: "/shop/checkout/reservation",
    meta: reservationEL7FytxwqKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/checkout/reservation.vue")
  },
  {
    name: "shop-suche",
    path: "/shop/suche",
    meta: sucheXzbG68SpR7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/suche.vue")
  },
  {
    name: "pds",
    path: "/shop/unsere-produkte/:parentCategory()/:childCategory()/:productType()/:webcode()-:slug().html",
    meta: _91webcode_93_45_91slug_93_46htmlwDFPOJe7xgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/[productType]/[webcode]-[slug].html.vue")
  },
  {
    name: "shop-unsere-produkte-parentCategory-childCategory-productType",
    path: "/shop/unsere-produkte/:parentCategory()/:childCategory()/:productType()",
    meta: indexMUM7Z4hjf3Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/[productType]/index.vue")
  },
  {
    name: "shop-unsere-produkte-parentCategory-childCategory",
    path: "/shop/unsere-produkte/:parentCategory()/:childCategory()",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/[childCategory]/index.vue")
  },
  {
    name: "shop-unsere-produkte-parentCategory",
    path: "/shop/unsere-produkte/:parentCategory()",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[parentCategory]/index.vue")
  },
  {
    name: "shop-unsere-produkte-slug.html",
    path: "/shop/unsere-produkte/:slug().html",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/unsere-produkte/[slug].html.vue")
  },
  {
    name: "shop-wishlist",
    path: "/shop/wishlist",
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/shop/wishlist.vue")
  },
  {
    name: "test-debug",
    path: "/test/debug",
    meta: debugwMBdxZ8jSxMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/vue-expert-shop/pages/test/debug.vue")
  }
]