<template>
  <template v-if="localStore !== undefined && localStore !== null && localStore?.id !== 'e_2879130'">
    <OnClickOutside
      class="local-store flyout flex flex-col border border-solid border-secondary w-full left-0 sm:left-auto sm:right-0 absolute sm:w-[350px] text-paragraph"
      @trigger="close">
      <div class="storeName items-center p-4 pt-2">
        <BasicIcon :name="'location-dot-solid'" class="w-4 text-primary" />
        <div class="grow">
          <div>{{ localStore?.name }}</div>
          <div class="text-primary cursor-pointer" @click="showStoreFinder = true;">Fachmarkt ändern</div>
          <Modal v-if="showStoreFinder" class="md" @close-modal="showStoreFinder = false; close();">
            <template #icon>
							<BasicIcon :name="'xmark-solid'" class="w-4 close-img"></BasicIcon>
						</template>
            <PopUpsStoreFinder style="max-height: 85%;" @storeChanged="showStoreFinder = false" />
          </Modal>
        </div>
      </div>
      <div class="storeAdress bg-secondary items-baseline p-4 pt-2">
        <img class="w-4" src="~/assets/mediums/Icons/house-chimney-solid.svg" />
        <div class="grow">
          {{ localStore?.street }}<br />
          {{ localStore?.zip }}
          {{ localStore?.city }}
        </div>
      </div>
      <div class="storeOpening bg-secondary items-baseline p-4 pt-0"
        v-if="localStore?.openingHours !== undefined && localStore?.openingHours !== null && localStore.openingHours.length > 0">
        <img class="w-4" src="~/assets/mediums/Icons/clock-nine-regular.svg" />
        <div class="flex gap-1 lg:grow grid" style="grid-template-columns: auto auto;">
          <template v-for="range in localStore.openingHours" :key="range">
            <div style="text-transform: capitalize;">{{ range.dayFrom }}.<template v-if="range.dayFrom !== range.dayTo">
                -
                {{ range.dayTo }}.</template></div>
            <div>{{ range.timeFrom }} - {{ range.timeTo }} Uhr</div>
          </template>
        </div>
      </div>
      <div class="storeTelephone bg-secondary items-center p-4 pt-0">
        <img class="w-4" src="~/assets/mediums/Icons/phone-solid.svg" />
        <div class="grow"><a class="text-primary" :href="'tel:' + localStore?.phone">{{ localStore?.phone }}</a></div>
      </div>
      <div class="storeMajorCustomerTelephone bg-secondary items-center p-4 pt-0">
        <img class="w-4" src="~/assets/mediums/Icons/phone-office-solid.svg" />
        <div class="grow">{{ localStore?.majorCustomerPhone }}</div>
      </div>
      <div class="storeEmail bg-secondary items-center p-4 pt-0" v-if="localStore?.email !== ''">
        <img class="w-4" src="~/assets/mediums/Icons/envelope-solid.svg" />
        <div class="grow"><a class="text-primary" :href="'mailto:' + localStore?.email">{{ localStore?.email }}</a></div>
      </div>
      <div class="storeRoute items-center p-4 pt-2">
        <img class="w-4" src="~/assets/mediums/Icons/map-pin-solid.svg" />
        <div class="grow">
          <div>Karte & Anfahrtbeschreibung</div>
          <a class="text-primary" :href="'https://www.google.com/maps/dir/?daddr='+localStore?.street+'+'+localStore?.zip+'+'" target="_blank">Route berechnen</a>
        </div>
      </div>
    </OnClickOutside>
  </template>
  <template v-if="localStore === undefined || localStore === null || localStore?.id === 'e_2879130'">
    <OnClickOutside class="local-store flyout flex flex-col border border-solid border-secondary w-full left-0 sm:left-auto sm:right-0 absolute sm:w-[350px]" @trigger="close">
      <div class="NoStore p-4">Kein Fachmarkt ausgewählt</div>
      <div class="noStoreButton">
        <button type="button" class="btn-primary w-full flex items-center justify-center p-4 gap-2" @click="showStoreFinder = true">
          <BasicIcon :name="'location-dot-solid'" class="w-4" />Fachmarkt finden
        </button>
        <Modal v-if="showStoreFinder" class="md" @close-modal="showStoreFinder = false">
          <template #icon>
            <BasicIcon :name="'xmark-solid'" class="w-4 close-img"></BasicIcon>
          </template>
          <PopUpsStoreFinder style="max-height: 85%;" @storeChanged="showStoreFinder = false" />
        </Modal>
      </div>
    </OnClickOutside>
  </template>
</template>
<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { ref, inject } from 'vue';

const showStoreFinder = ref(false)
const showLocalStoreFinder = false
function close() {
  emit("changeFlyOutState", showLocalStoreFinder);
}
const emit = defineEmits(["changeFlyOutState"]);

//FUNKTION VON FLO UND MIR VOM 25.07.23
const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const localStore = storeRef?.currentStore;

</script>
