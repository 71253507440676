<template>
  <div class="flex flex-col theme-default text-sm">
    <p class="text-primary text-lg py-2">Versandkosten</p>
    <p class="border-b py-2">
      Bei vielen Artikeln übernehmen wir für Sie die Versandkosten. Sollten doch
      Versandkosten entstehen, entnehmen Sie diese bitte der untenstehenden Tabelle:
    </p>
    <p class="font-bold text-paragraph-light pt-4 pb-2">
      Paketversand an eine beliebige Adresse Deutschlands
    </p>
    <div class="grid grid-cols-6 border-t py-2">
      <div class="col-span-5">Standard</div>
      <div class="text-right">6,99 €</div>
    </div>
    <div class="grid grid-cols-6 py-2 border-b">
      <div class="col-span-5">Sperrgut</div>
      <div class="text-right">14,99 €</div>
    </div>
    <p class="py-2">
      Die Versandkostenfreiheit eines Artikels gilt immer für den jeweiligen Artikel bei
      entsprechender Auszeichnung. <br />
      Bei der zusätzlichen Auswahl von versandkostenpflichtigen Artikeln können für diese
      Versandkosten entstehen.
    </p>
    <p class="font-bold text-paragraph-light pt-4 pb-2 border-b">
      Speditionsversand an eine beliebige Adresse Deutschlands
    </p>
    <div class="grid grid-cols-6 py-2">
      <p class="col-span-5">Lieferung bis zur Bordsteinkante inkl. Avis</p>
      <p class="text-right">59,90 €</p>
    </div>
    <div class="grid grid-cols-6 py-2">
      <p class="col-span-5">
        Lieferung bis zur Bordsteinkante inkl. Avis (Side-by-Side Kühlgeräte)
      </p>
      <p class="text-right">79,90 €</p>
    </div>
    <div class="grid grid-cols-6 py-2">
      <p class="col-span-5">Lieferung frei Verwendungsstelle inkl. Avis</p>
      <p class="text-right">79,90 €</p>
    </div>
    <p class="py-2 border-b">
      Die Spedition stimmt einen Liefertermin mit Ihnen ab, dadurch kann sich die beim
      Artikel ausgewiesene Lieferzeit verlängern. In der aktuellen Situation kann es sein,
      dass die Lieferung zum Verwendungsort leicht eingeschränkt ausgeführt wird, z.B. der
      Spediteur bis hinter die Wohnungseingangstür liefert, sich aber aus Schutzgründen
      nicht innerhalb Ihrer Wohnung bewegt.
    </p>
    <p class="font-bold text-paragraph-light pt-4 pb-2 border-b">Versand per E-Mail</p>
    <p class="py-2">
      Beim Kauf von virtuellen Produkten erhalten Sie innerhalb von 15 Minuten einen
      entsprechenden Code zur Aktivierung Ihres Software-Produkts per E-Mail. Hierfür
      fallen keine weiteren Versandkosten für Sie an. Produkte in der digitalen Version
      können nicht zurückgesandt werden und sind nicht erstattbar. Sie stimmen
      ausdrücklich zu, dass mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist
      begonnen wird und Ihnen ist bekannt, dass Sie durch diese Zustimmung Ihr
      Widerrufsrecht verlieren.
    </p>
    <p class="font-bold text-paragraph-light pt-4 pb-2 border-b">Entsorgung Ihrer Altgeräte</p>
    <p class="py-2">
      Bei einer Speditionslieferung nehmen wir Ihr Altgerät kostenlos mit und entsorgen es
      fachgerecht. Wählen Sie beim Kauf die Altgerät-Mitnahme als zusätzliche Leistung aus
      und stellen Sie das Altgerät bereit.
    </p>
    <p class="py-2">
      Bei einer Paketlieferung ist aufgrund technischer Restriktionen ein 1:1 Umtausch im
      Zuge der Lieferung noch nicht möglich. Melden Sie sich bitte bei der Hotline, um ein
      Versandlabel zu erhalten.
    </p>
    <p class="py-2">
      Des Weiteren nehmen wir Ihr Elektrogerät kostenlos an einer unserer Sammelstellen
      entgegen.
    </p>
    <p class="py-2">
      Geht aufgrund einer Verunreinigung des Altgeräts eine Gefahr für die Gesundheit und
      Sicherheit von Menschen aus, so kann die Annahme verweigert werden. Dies gilt aber
      grundsätzlich nicht für beschädigte Altgeräte.
    </p>
    <p class="font-bold text-paragraph-light pt-4 pb-2 border-b">
      Ausgeschlossene Abhol- und Zustelladressen
    </p>
    <p class="py-2">
      Sendungen, bei denen die Abholadresse oder die Zustelladresse ungeeignet oder nur
      unter unverhältnismäßigen Schwierigkeiten erreichbar sind, sind ausgeschlossen.
    </p>
    <p class="py-2">
      Dazu zählen Postfachanschriften, Packstationen,weitläufige Firmengelände,
      Kleingartenanlagen, Kasernengelände, weitläufige Behördengebäude sowie
      Großkundenpostleitzahlen.
    </p>
  </div>
</template>
