<template>
  <div class="p-4">
    <template v-if="isShowFirstPage()">
      <template v-if="!showSecondSite">
        <div class="text-left modal-order flex flex-col gap-4">
          <div class="font-bold text-lg text-primary">
            Unsere Service Angebote für Sie.
          </div>
          <div class="content">
            <ReservationTabMenu :price="price?.value" :showInput="true" :prefix="article?.articleId"
              @modify-option="handleModifyOption" />
          </div>
          <button class="btn-primary w-full" @click="
            $emit('allowOverflow', false);
          toggleSecondPage();
          ">
            Weiter
          </button>
        </div>
      </template>
    </template>
    <template v-if="!isShowFirstPage()">
      <div class="text-left modal-order flex flex-col gap-4">
        <div class="font-bold text-lg">
          Das Produkt wurde erfolgreich zu Ihrer Reservierung hinzugefügt
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="img">
            <ArticleExpMedium :mediumId="article?.primaryImage" :preset="'ppmsmall'">
            </ArticleExpMedium>
            <ArticleEnergyEfficiency :showDataFile="true" class="grow text-right" :article="article" />
          </div>
          <div>
            <div class="font-bold line-clamp-2">{{ article?.headline }}</div>
          </div>
          <div class="text-right col-span-2">
            <ArticleExpPrice :enableDeliveryModal="false" :showShipmentInformation="false"
              :deliveryInfoClass="'whitespace-nowrap direction-reverse'" :displayPriceDesignClasses="'text-5xl relative'"
              :enableUVPLabel="false" :enableEnergyLabel="false" :addsReserveSpace="true" :pprice="price"
              class="pt-3 mt-3 text-right" :popOverClass="'text-left w-[300px]'" />
          </div>
        </div>
        <NuxtLink :to="'/shop/checkout/reservation?step=1'" class="btn-primary text-center block"
          v-if="useFeatureState('ES-5386-Reservation').value == true">
          <BasicIcon :name="'cart-shopping-solid'" class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" />
          Zur Reservierung
        </NuxtLink>
        <NuxtLink :to="'/' + currentStore + '#1946cb49-f42c-4e42-b13a-05016fed4c59'" :external="true"
          class="btn-primary text-center block" v-else>
          <BasicIcon :name="'cart-shopping-solid'" class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" />
          Zur Reservierung
        </NuxtLink>
        <a class="btn-primary text-center block cursor-pointer" @click="$emit('close-modal')">
          Weiter einkaufen
        </a>
        <template v-if="addArtCategoriesMap?.keys()">
          <strong v-if="checkForCrossSale()">Das könnte Sie auch interessieren</strong>
          <Tabs class="article-accessories" :options="{ useUrlFragment: false }" :cache-lifetime="0"
            @click="scrollToFirstEntry($event)">
            <Tab v-for="(categories, index) in addArtCategoriesMap?.keys()" :key="categories"
              :name="' ' + getCategoryLabel(categories)" :id="'tabcontent_' + index">
              <template v-for="entry in addArtCategoriesMap?.get(categories)" :key="entry.id">
                <template v-if="reservationAction.includes(entry?.expPrice?.storeAvailability)">
                  <ArticleExpPrice :showDataFile="true" :enableDeliveryModal="false" :enableUVPLabel="false"
                    :showEEL="true" :showShipmentInformation="false" :addsReserveSpace="true"
                    :localarticle="entry?.expArticle" class="border-t pt-3 mt-3" :popOverClass="'text-left w-[250px]'">
                    <template #beforePrice="{ finalPrice }">
                      <NuxtLink :to="entry?.link" v-if="finalPrice?.bruttoPrice">
                        <div class="brand text-lg text-primary line-clamp-2 h-8">
                          {{ entry?.brand }}
                        </div>
                        <div class="name text-sm line-clamp-5">
                          {{ entry?.name }}
                        </div>
                        <div class="relative img" style="min-height: 175px">
                          <div class="absolute top-3 left-0">
                            <LazyPromotionBubble :article-id="entry?.expArticle?.articleId" :price="price?.value">
                            </LazyPromotionBubble>
                          </div>
                          <ArticleExpMedium :mediumId="entry?.image" style="margin: auto" :lazy="true" preset="ppmsmall">
                          </ArticleExpMedium>
                        </div>
                      </NuxtLink>
                    </template>
                    <template #afterPrice="{ finalPrice }">
                      <div class="text-success" :id="entry?.expArticle?.articleId + '-greenArrow'"
                        style="top: 110%; right: 0px; display: none">
                        Als Reservierung zurückgelegt
                        <BasicIcon :name="'check-regular'" class="w-4 scale-150 align-baseline text-success"
                          style="margin-left: 12px" />
                      </div>
                      <div v-if="finalPrice?.bruttoPrice">
                        <button class="articleStatus" @click="addToCart(entry)" type="button">
                          <BasicIcon :name="'cart-shopping-solid'" class="w-4 mr-2 align-text-top" />
                          Reservieren
                        </button>
                      </div>
                    </template>
                  </ArticleExpPrice>
                </template>
              </template>
            </Tab>
          </Tabs>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { inject } from "vue";
import ReservationTabMenu from "~/components/Article/ReservationTabMenu.vue";
import { ExpArticle } from "~/models/ExpArticle/exp_Article";
import { Tabs, Tab } from "vue3-tabs-component";
import ArticleExpMedium from "~/components/Article/ExpMedium.vue";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import {
  useReservationCartItemsCount,
  useCurrentReservationCart,
} from "~/composables/states";
import { addArticleToResCart } from "~/composables/ExpApi/expCartActions";
import { getExpNavigationTree } from "~/composables/ExpApi/expCache";
import { getCategoryFromNavigationTree } from "~/composables/ExpApi/expCategory";

const props = defineProps({
  price: {
    type: Object,
    default: () => {
      return inject("primaryPrice");
    },
  },
  image: {
    default: () => {
      return inject("primaryImages");
    },
  },
  activePromotions: {
    default: () => {
      return inject("primaryPromotions");
    },
  },
  article: {
    type: Object,
    default: () => {
      return inject("primaryArticle");
    },
  },
  fakeSecondPageCall: {
    type: Boolean,
  },
  page: {
    type: Number,
    default: 1,
  },
  addArtCategoriesMap: {
    type: Map,
  },
  activeState: {
    type: String,
  },
});

const emit = defineEmits(["close-modal", "fakeSecondPageCallEmit", "allowOverflow"]);
const showSecondSite = ref(false);
const user: any = await getExpUserProfile();
const deleteError = ref(null);
const reservationAction = ["AVAILABLE", "PREORDER", "PROCURABLE"];
const refreshR = ref(() => { });

await addArticleToResCart(props?.article?.articleId, []);
const cart = ref(await useCurrentReservationCart().value);
const { data: fetchedCart, refresh } = await fetchCurrentCart(user?.reservationCartId);

cart.value = fetchedCart.value;
refreshR.value = refresh;

const getCartArticleId = (articleId: String) => {
  let cartArticleId = "";

  cart?.value?.itemList?.forEach(currentElement => {
    if (currentElement?.articleId === articleId) {
      cartArticleId = currentElement.id;
      refreshR.value();
    }
  });

  return cartArticleId;
}

async function toggleSecondPage() {
  showSecondSite.value = true;
  emit("allowOverflow", false);
  emit("fakeSecondPageCallEmit", props?.fakeSecondPageCall);
}
function isShowFirstPage() {
  if (props?.fakeSecondPageCall) {
    return false;
  }
  if (!showSecondSite.value) {
    if (checkForServiceQuantity()) {
      return true;
    } else {
      showSecondSite.value = true;
      emit("allowOverflow", false);
      return false;
    }
  }
  return false;
}

function checkForServiceQuantity() {
  if (
    props?.price?.value?.availableServices !== undefined &&
    props?.price?.value?.availableServices !== null
  ) {
    if (props?.price?.value?.availableServices?.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function getAllServices(services) {
  let allServices = []
  if(services instanceof Array) services?.forEach(service => {
    getAllServices(service.childService)?.forEach(childService => {
      allServices.push(childService?.id?.replace('e_', ''))
    });
    if(typeof service?.id == 'string') allServices.push(service?.id?.replace('e_', ''))
  });
  return allServices
}

async function resetArticleServices() {
  const allServices = getAllServices(props?.price?.value?.availableServices);
  const articleId = inject("primaryArticle")?.articleId ? inject("primaryArticle")?.articleId : props?.article?.articleId;
  await modifyAdditionalServiceOption(
    "reservationcart",
    getCartArticleId(articleId),
    allServices,
    false,
    user?.csrfToken
  );
}
resetArticleServices();

async function handleModifyOption(eve: any) {
  const articleId = inject("primaryArticle")?.articleId ? inject("primaryArticle")?.articleId : props?.article?.articleId;
  await modifyAdditionalServiceOption(
    "reservationcart",
    getCartArticleId(articleId),
    eve?.entryId,
    eve?.value,
    user?.csrfToken
  );
}
const currentStore = useCurrentStore()?.value?.storeLink;
// await refreshCSRFToken();
async function addArticleToReservation() {
  const user = await getExpUserProfile();

  if (user !== null && user.reservationCartId !== null) {
    let key = Date.now().toString();
    const { data: add } = await addArticleToReservationCart(
      props?.article?.articleId,
      1,
      user?.csrfToken,
      []
    );
    if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
      add.value.overAllQuantity = 0
      for (let a = 0; a < add?.value?.itemList?.length; a++) {
        add.value.overAllQuantity += add?.value?.itemList[a].quantity;
      }
    }
    useReservationCartItemsCount().value = isNaN(add?.value?.overAllQuantity)
      ? 0
      : add?.value?.overAllQuantity;
  }
}

const { data: navigationTree } = await getExpNavigationTree("MAIN");

function getCategoryLabel(categoryId: string) {
  return getCategoryFromNavigationTree(categoryId, navigationTree)?.name;
}

function scrollToFirstEntry(event: any) {
  if (event.srcElement.classList.contains("tabs-component-tab-a")) {
    location.href =
      "#" +
      document.querySelectorAll(
        '.modal-overlay .tabs-component-panel:not([style*="display: none"]'
      )[0].id;
  }
}

async function removeArticleFromCart(articleId: any) {
  console.log("removeArticleFromCart : article : ", articleId);
  let itemId: string = "";
  let actualQuantity: number = 0;
  await useCurrentReservationCart().value.itemList?.forEach((element: any) => {
    if (element.articleId == articleId) {
      itemId = element.id;
      actualQuantity = element.quantity - 1 >= 0 ? element.quantity - 1 : 0;
    }
  });
  if (itemId != "") {
    console.log("removeArticleFromCart itemId ist nicht leer :  ", itemId);
    const freshUser = await getExpUserProfile();
    console.log("removeArticleFromCart freshUSer ; ", freshUser);
    if (freshUser?.reservationCartId) {
      const res = await modifyArticleQuantityInShoppingCart(
        "reservationcart",
        itemId,
        actualQuantity,
        freshUser?.csrfToken
      );
      if (res.value == null) {
        useReservationCartItemsCount().value -= 1;
        useCurrentReservationCart().value = fetchCurrentCart(
          freshUser?.reservationCartId
        );
        return Promise.resolve();
      } else {
        if (deleteError.value != null) {
          (deleteError.value as HTMLElement).innerText = "Es ist ein Fehler Aufgetreten.";
        }
      }
    }
  } else {
    if (deleteError.value != null) {
      (deleteError.value as HTMLElement).innerText = "Item nicht gefunden.";
    }
  }
  return Promise.reject();
}
function checkForCrossSale() {
  if (props?.addArtCategoriesMap !== null) {
    if (props?.addArtCategoriesMap.size > 0) {
      // showSecondSite.value = false
      return true;
    } else {
      return false;
    }
  } else {
    // showSecondSite.value = true
    return false;
  }
}

async function addToCart(article: Object) {
  if (article?.expArticle?.articleId !== undefined) {
    document.getElementById(
      article?.expArticle?.articleId + "-greenArrow"
    ).style.display = "inline-block";
  }
  await addArticleToResCart(article?.expArticle?.articleId, []);
}
</script>
