import { useFetch, useLazyFetch } from 'nuxt/app';
import type { ShoppingCart } from '~/models/shoppingCart';
import { getExpUserProfile } from './ExpApi/expUser';
import { v4 as uuidv4 } from "uuid";
import { deepEqual } from '@grafana/faro-web-sdk';
import { getPrice } from "~/composables/ExpApi/expPrice";

export const fetchCurrentCart = (shoppingCartId: string) => {
	return useFetch('/api/neo/shoppingCart-service/getShoppingCart', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': useCurrentUserState().value?.csrfToken
		},
		method: 'POST',
		key: 'cart_' + shoppingCartId,
		server: false,
		body: {
			'shoppingCartId': shoppingCartId
		},
		transform(context) {
			const data = context;
			const cart: ShoppingCart = {
				itemList: [],
				itemListId: '',
				coupons: [],
				currency: '',
				cartType: '',
				cartId: '',
				lastAddedArticleId: '',
				lastAddedArticleQuantity: 0,
				lastAddedArticlePriceGross: 0,
				lastAddedArticleAdditionalItems: [],
				totalBill: 0,
				subTotalBill: 0,
				totalMwst: 0,
				additionalArtPrice: 0,
				overAllQuantity: 0
			}
			if (data) {
				const body = data;
				cart.cartType = body.cartType
				cart.cartId = body.id
				cart.coupons = body.coupons
				cart.currency = body.currency
				cart.itemList = body.itemList.items
				cart.itemListId = body.itemList.id
				cart.lastAddedArticleId = body.lastAdded?.id
				cart.lastAddedArticleQuantity = body.lastAdded?.quantity
				cart.lastAddedArticlePriceGross = body.lastAdded?.price?.gross
				cart.lastAddedArticleAdditionalItems = body.lastAdded?.additionalItems
				let counter: number = 0
				let mwst: number = 0
				if (cart?.itemList !== undefined) {
					for (let a = 0; a < cart?.itemList?.length; a++) {
						if (cart?.itemList[a].additionalPriceSum === undefined) {
							cart.itemList[a].additionalPriceSum = 0
						}
						if (cart?.itemList[a].additionalItems?.items.length > 0) {
							for (let b = 0; b < cart?.itemList[a].additionalItems?.items.length; b++) {
								cart.itemList[a].additionalPriceSum += cart.itemList[a].additionalItems.items[b].price.gross
							}
						}
						counter += cart?.itemList[a].price?.gross * cart.itemList[a].quantity
						mwst += cart?.itemList[a].price?.gross - cart.itemList[a].price?.net
					}
				}
				cart.subTotalBill = counter
				cart.totalMwst = mwst
			}
			return cart
		}
	})
}

export async function redeemVoucher(csrfToken: string, voucher: string, captcha: string) {
	let body = {}
	body.voucher = voucher
	if (captcha) body.captcha = captcha
	return await useFetch('/api/neo/frontend/_api/shoppingcart/redeemVoucher', {
		method: 'POST',
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		body: body
	})
}

export function addArticleToShoppingCart(articleId: string, quantity: number, csrfToken: string, options?: string[]) {
	return addArticleToSomeCart('shoppingcart', articleId, quantity, csrfToken, options)
}

export function addArticleToReservationCart(articleId: string, quantity: number, csrfToken: string, options?: string[]) {
	return addArticleToSomeCart('reservationcart', articleId, quantity, csrfToken, options)
}

export function addArticleToSomeCart(cartType: string, articleId: string, quantity: number, csrfToken: string, options?: string[]) {
	useGeneralTrick({
		eventType: 'ADD_TO_CART_ITEM',
		sessionId: useCurrentSessionIdentifier()?.value,
		json: {
			cartType: cartType == 'shoppingcart' ? 'SHOPPING_CART' : 'RESERVATION_CART',
			articleId: articleId,
			quantity: quantity,
		},
	});
	return useLazyFetch('/api/neo/frontend/_api/' + cartType + '/addItem', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			article: articleId,
			quantity: quantity,
			options: options
		},
		async onResponse(context) {
			// const validationData = getRaw((await validateCart(csrfToken)).value)
			// for(let key in validationData) {
			// 	for(let i = 0; i < validationData[key]?.failures?.length; i++) {
			// 		let error = validationData[key].failures[i]
			// 		if(error?.namespace == "QuantityCartRule" && error?.errorcode == 9) {
			// 			await modifyArticleQuantityInShoppingCart(cartType, error.id, 3, csrfToken);
			// 			throw createError({
			// 				statusCode: 9,
			// 				statusMessage: "QuantityCartRule",
			// 				cause: error,
			// 				fatal: true,
			// 			  });
			// 		}
			// 	}
			// }
			const data = context.response._data
			const cart: ShoppingCart = mapShoppingCart(data.shoppingCart)
			if (context?.error) {
				console.error('could not add article to cart ' + context?.error);
				// throw context?.error;
			}
			const validationData = getRaw((await validateCart(csrfToken)).value)
			let { data: price } = await getPrice(articleId, useCurrentStore(), false, false);
			let maxQuantity = Math.min(3, (cartType == "shoppingcart" ? price?.value?.onlineStock : price?.value?.storeStock))
			for (let i = 0; i < cart?.itemList?.length; i++) {
				let item = cart?.itemList[i]
				if (item.articleId == articleId && item.quantity > maxQuantity) {
					await modifyArticleQuantityInShoppingCart(cartType, item.id, maxQuantity, csrfToken);
					throw createError({
						statusCode: 500,
						statusMessage: "QuantityCartRule",
						cause: {
							namespace: "QuantityCartRule",
							errorcode: 9
						},
						fatal: true,
					});
				}
			}
			for (let key in validationData) {
				for (let i = 0; i < validationData[key]?.failures?.length; i++) {
					let error = validationData[key].failures[i]
					if (error?.namespace == "QuantityCartRule" && error?.errorcode == 9) {
						await modifyArticleQuantityInShoppingCart(cartType, error.id, maxQuantity, csrfToken);
						throw createError({
							statusCode: 500,
							statusMessage: "QuantityCartRule",
							cause: error,
							fatal: true,
						});
					}
				}
			}
			if (data?.shoppingCart)
				context.response._data = cart
			else {
				console.log('addArticleToSomeCart had an error');
			}
		}
	})
}

export const getValidateCartResponse = async (cartId: string, storeId: string, cacheLevel?: 'LIVE' | 'DEFAULT' | 'LONG') => {
	console.debug('getUpdatedShoppingCartWithCacheLevel', cartId, storeId, cacheLevel);
	const respo = await $fetch('/api/neo/shoppingCart-service/getUpdatedShoppingCartWithCacheLevel', {
		body: {
			clientId: storeId?.startsWith('e_') ? storeId : 'e_' + storeId,
			shoppingCartId: cartId,
			priceCacheLevel: cacheLevel || 'DEFAULT'
		},
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': useCurrentUserState().value?.csrfToken
		},
		method: 'POST',
	});
	console.debug('response from fetch', respo);
	if (!deepEqual(respo?.oldCart, respo?.newCart)) {
		console.warn('carts not equal');
		itemListDescriptionRequestFix(respo?.oldCart?.itemList);
		if(respo?.oldCart?.lastAdded?.itemOnDisplayDescription) respo.oldCart.lastAdded.itemOnDisplayDescription = replaceMutetedCharacters(respo?.oldCart?.lastAdded?.itemOnDisplayDescription)
		// await respo?.newCart?.itemList?.items.forEach(async (item) => {
		const failures = await $fetch('/api/neo/order-service/validateCart', {
			body: {
				clientId: storeId?.startsWith('e_') ? storeId : 'e_' + storeId,
				priceCacheLevel: cacheLevel || 'DEFAULT',
				cart: respo?.oldCart
			},
			headers: {
				'bt-use-user-auth': 'true',
				'csrf-token': useCurrentUserState().value?.csrfToken
			},
			method: 'POST',
		});
		return failures;
		// console.debug('response from fetch2', respo2);
		// })
		// throw {
		// 	message: "error",
		// 	cause: { namespace: 'reservation', errorcode: 104 }
		// };
	}
};

export const updateShoppingCartWithCacheLevel = async (cartId: string, storeId: string, cacheLevel?: 'LIVE' | 'DEFAULT' | 'LONG') => {
	const respo = await $fetch('/api/neo/shoppingCart-service/getUpdatedShoppingCartWithCacheLevel', {
		body: {
			clientId: storeId?.startsWith('e_') ? storeId : 'e_' + storeId,
			shoppingCartId: cartId,
			priceCacheLevel: cacheLevel || 'DEFAULT'
		},
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': useCurrentUserState().value?.csrfToken
		},
		method: 'POST',
	});
	itemListDescriptionRequestFix(respo?.newCart?.itemList);
	if(respo?.newCart?.lastAdded?.itemOnDisplayDescription) respo.newCart.lastAdded.itemOnDisplayDescription = replaceMutetedCharacters(respo?.newCart?.lastAdded?.itemOnDisplayDescription)
	await respo?.newCart?.itemList?.items?.forEach(async (item) => {
		console.debug('updating item', item);
		const response2 = await $fetch('/api/neo/shoppingCart-service/updateItem', {
			body: {
				shoppingCartId: cartId,
				itemId: item?.id,
				item: item,
			},
			headers: {
				'bt-use-user-auth': 'true',
				'csrf-token': useCurrentUserState().value?.csrfToken
			},
			method: 'POST',
		});
		console.debug('got updated item response', response2);
	});

}

function mapShoppingCart(data: Object) {
	const cart: ShoppingCart = {
		itemList: [],
		itemListId: '',
		coupons: [],
		currency: '',
		cartType: '',
		cartId: '',
		lastAddedArticleId: '',
		lastAddedArticleQuantity: 0,
		lastAddedArticlePriceGross: 0,
		lastAddedArticleAdditionalItems: [],
		totalBill: 0,
		subTotalBill: 0,
		totalMwst: 0,
		additionalArtPrice: 0,
		overAllQuantity: 0
	};
	if (data) {
		const body = data;
		cart.cartType = body.cartType;
		cart.cartId = body.id;
		cart.coupons = body.coupons;
		cart.currency = body.currency;
		cart.itemList = body.itemList.items;
		cart.itemListId = body.itemList.id;
	}
	return cart;
}

export async function modifyArticleQuantityInShoppingCart(cartType: string, articleId: string, quantity: number, csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/' + cartType + '/modifyItemQuantity', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			itemId: articleId,
			quantity: quantity
		}
	})
	useGeneralTrick({
		eventType: 'ADD_TO_CART_ITEM',
		sessionId: useCurrentSessionIdentifier()?.value,
		json: {
			cartType: cartType == 'shoppingcart' ? 'SHOPPING_CART' : 'RESERVATION_CART',
			articleId: articleId,
			quantity: quantity,
		},
	});
	if (error?.value) {
		console.error('could not modify article quantity in shoppingCart ' + error?.value);
		throw error?.value;
	}
	console.log("modifyArticleQuantityInShoppingCart Response : ", data)
	return data;
}

export async function addItemToShoppingCart(cartType: string, articleId: string, quantity: number, csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/' + cartType + '/addItem', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			article: articleId,
			quantity: quantity
		}
	})
	return data;
}

export async function validateCart(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/validateCart', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {}
	})
	return data
}

const address = {
	'id': 'klsadfuklsdjf',
	'title': 'MS',
	'firstName': 'Melanie',
	'lastName': 'Bla',
	'street': 'Bla.',
	'zip': '33039',
	'city': 'Bla',
	'countryCode': 'DE',
	'company': null,
	'houseNumber': '16',
	'addition': null,
	'email': 'kkk@gmx.de',
	'fax': null,
	'mobile': null,
	'telephone': '999999'
}

export async function modifyAdditionalServiceOption(cartType: string, itemId: string, options: string | string[], state: boolean, csrfToken: string) {
	console.log("modifyAdditionalServiceOption :  ", {
		cartType: cartType,
		itemId: itemId,
		options: options,
		state: state,
		csrfToken: csrfToken
	})
	const { error, data } = await useFetch('/api/neo/frontend/_api/' + cartType + '/modifyAdditionalServiceOption', {
		method: 'POST',
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		body: {
			state: state,
			options: Array.isArray(options) ? options : [options?.replace('e_', '')],
			itemId: itemId
		}
	})
	if (error?.value) {
		console.error('could not modify service option in cart ' + error?.value);
		throw error?.value;
	}
	console.log("modifyAdditionalServiceOption Response : ", data)
	return data;
}

export async function calculateFakeShippingBins(itemList: any[], csrfToken: string) {
	itemListDescriptionRequestFix(itemList)
	const { error, data } = await useFetch('/api/neo/shipping-service/calculateShippingBins', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			'itemList': { items: itemList.map(ite => { return { ...ite, expArticle: undefined } }) },
			'address': address,
			'applyDefaults': true
		}
	})
	if (error?.value) {
		console.error('Error calculating fake shipping bins');
	} else if (data?.value) {
		return data.value.bins
	}
}

export async function getShippingMethodOptionsByIds(csrfToken: string, methArray: []) {
	const { error, data } = await useFetch('/api/neo/shipping-service/getShippingMethodOptionsByIds', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			'ids': methArray
		}
	})
	if (error?.value) {
		console.error('Error getting shippingmethods by ids');
	} else if (data) {
		return data
	}
}

export async function getShippingMethodOptions(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/shipping-service/getShippingMethodOptions', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	if (error?.value) {
		console.error('Error getting shippingmethods');
	} else if (data) {
		return data
	}
}

export async function reserve() {
	let address = {
		...useReservationFormData()?.value,
		countryCode: 'DE',
		// id: uuidv4(),
		street: useReservationFormData()?.value?.street ? useReservationFormData()?.value?.street : "",
		zip: useReservationFormData()?.value?.zip ? useReservationFormData()?.value?.zip : "",
		city: useReservationFormData()?.value?.city ? useReservationFormData()?.value?.city : "",
		houseNumber: useReservationFormData()?.value?.houseNumber ? useReservationFormData()?.value?.houseNumber : "",
	}
	let person = {
		firstName: useReservationFormData()?.value?.firstName ? useReservationFormData()?.value?.firstName : "",
		lastName: useReservationFormData()?.value?.lastName ? useReservationFormData()?.value?.lastName : "",
		title: useReservationFormData()?.value?.title ? useReservationFormData()?.value?.title : "UNKNOWN",
		email: useReservationFormData()?.value?.email ? useReservationFormData()?.value?.email : '',
		telephone: useReservationFormData()?.value?.telephone ? useReservationFormData()?.value?.telephone : '',
		company: useReservationFormData()?.value?.company ? useReservationFormData()?.value?.company : ''
	}
	const freshUser = await getExpUserProfile()

	const resFeature = await $fetch('/api/neo/frontend/_api/features/get', {
		method: "POST", body: {
			"type": "Reservation"
		}, headers: {
			'csrf-token': useCurrentUserState()?.value?.csrfToken,
			'bt-use-user-auth': 'true'
		}
		, server: false
	})

	console.debug('got features', resFeature);

	delete address['email'];
	delete address['id'];
	delete address['company'];
	delete address['country'];
	delete address['fax'];
	delete address['mobile'];
	delete address['customerNumber'];
	delete address['telephone'];
	delete address['firstName'];
	delete address['lastName'];
	delete address['title'];

	return await useFetch('/api/neo/frontend/_api/reservation/reserve', {
		method: 'POST',
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': freshUser?.csrfToken
		},
		body: {
			person: person,
			address: address,
			useAddress: true,
			_rev: resFeature?.rev,
			text: useReservationFormAdditionalInfo()?.value?.comment,
			newsletter: useReservationFormAdditionalInfo()?.value?.newsletterOptIn
		}
	})

}

export async function getReservation(csrfToken: string, reservationId: string) {
	const { error, data } = await useFetch('/api/neo/reservation-service/getReservation', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			reservationId: reservationId
		}
	})
	return data
}

export async function getUserOrderProcess(csrfToken: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/getUserOrderProcess', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
}

export async function proceedWithRegistration(csrfToken: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/proceedWithRegistration', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
}

export async function proceedAsGuest(csrfToken: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/proceedAsGuest', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
}

export async function login(csrfToken: string, email: string, password: string, captcha: string) {
	let body = {
		login: {
			email: email,
			password: password
		}
	}
	if (captcha) body.captcha = captcha
	let { error, data } = await useFetch('/api/neo/frontend/_api/user/login', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: body,
		watch: false
	});
	if (error?.value) {
		let errCResp = error?.value?.cause?.response?._data;
		// let errMsg = errResp?.body await errResp.json();
		throw new Error("Error Logging in", { cause: errCResp });
	}
	return data
}

export async function registerUserFromPayload(csrfToken: string, password: string) {
	return await useFetch('/api/neo/frontend/_api/user/registerUserFromPayload', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			password: password
		}
	})
}

export async function getSystemUser(csrfToken: string, systemUserId: string) {
	const { error, data } = await useFetch('/api/neo/user-service/getSystemUser', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			systemUserId: systemUserId
		}
	})
	return data
}

export async function addNewAddress(csrfToken: string, address: Object) {
	let { error, data } = await useFetch('/api/neo/frontend/_api/user/addNewAddress', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			address: address
		}
	})
	return data
}

export async function getBrand(csrfToken: string, brandId: string) {
	const { error, data } = await useFetch('/api/neo/article-service/getBrand', {
		key: 'brandId=' + brandId,
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			brandId: brandId
		}
	})
	return data
}

export async function updateOrderFlags(csrfToken: string, newsletterFlag: boolean, termsFlag: boolean, insuranceFlag: boolean) {
	return await $fetch('/api/neo/frontend/_api/checkout/updateOrderFlags', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			"newsletterFlag": newsletterFlag,
			"termsFlag": termsFlag,
			"insuranceFlag": insuranceFlag
		}
	})
}

export async function setPersonalData(csrfToken: string, requestData: Object) {
	if (requestData?.invoiceAddress && !requestData?.invoiceAddress?.telephone)
		requestData.invoiceAddress.telephone = ""
	if (requestData?.shippingAddress && !requestData?.shippingAddress?.telephone)
		requestData.shippingAddress.telephone = ""
	return await useFetch('/api/neo/frontend/_api/checkout/setPersonalData', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: requestData
	})
}

export async function trackGoogleAddressInput(csrfToken: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/googleAddressInput', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST'
	})
}

export async function trackManualAddressInput(csrfToken: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/manualAddressInput', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST'
	})
}

export async function setInvoiceAddress(csrfToken: string, orderId: string, invoiceAddress: Object, isPaypal: boolean) {
	if (!invoiceAddress?.telephone)
		invoiceAddress.telephone = ""
	return await useFetch('/api/neo/frontend/_api/checkout/' + (isPaypal ? 'setPaypalInvoiceAddress':'setInvoiceAddress'), {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			orderId: orderId,
			invoiceAddress: invoiceAddress
		}
	})
}

export async function getOrder(csrfToken: string, orderId: string) {
	const { error, data } = await useFetch('/api/neo/order-service/getOrder', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			orderId: orderId
		}
	})
	return data
}

export async function startOrderProcess(csrfToken: string, orderId: string) {
	let requestData = {}
	if (orderId) requestData.orderId = orderId
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/startOrderProcess', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: requestData
	})
	return data
}

export async function changeShippingAspects(csrfToken: string, orderId: string, itemIds: string[], selectedCarrier: string, selectedShippingMethod: string, aspectOptions: string[]) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/changeShippingAspects', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			orderId: orderId,
			itemIds: itemIds,
			selectedCarrier: selectedCarrier,
			selectedShippingMethod: selectedShippingMethod,
			aspectOptions: aspectOptions
		}
	})
	return data
}

export async function getShippingCarriers(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/shipping-service/getShippingCarriers', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	return data
}

export interface TaxValue {
	taxRate: number;
	taxes: number;
}

export interface SummedPrice {
	net: number;
	gross: number;
	taxValues: TaxValue[];
	currency: string;
}

export interface ItemList {
	items: Object[];
	id: string;
}

//TODO: requests containing mutated characters (äöüß) currently don't work
//better fix the actual problem and not just replace characters in every request
function itemListDescriptionRequestFix(itemList) {
	let items = itemList
	if (itemList?.items) items = itemList.items
	items?.forEach(item => {
		itemListDescriptionRequestFix(item?.additionalItems)
		item.itemOnDisplayDescription = replaceMutetedCharacters(item.itemOnDisplayDescription)
	});
}

function replaceMutetedCharacters(dirtyString: string) {
	let cleanedUp = dirtyString;
	try {
		cleanedUp = dirtyString.replace('Ä', 'Ae').replace('Ö', 'Oe').replace('Ü', 'Ue').replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue').replace('ß', 'ss')
	} catch (error) {
		console.warn(error)
	}
	return cleanedUp;
}

export async function getAvailablePaymentChannels(csrfToken: string, combinedShippingCost: SummedPrice, combinedItemList: ItemList, userProfileId: string, b2b: boolean) {
	itemListDescriptionRequestFix(combinedItemList)
	const { error, data } = await useFetch('/api/neo/payment-service/getAvailablePaymentChannels', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		key: 'availablePaymentChannels',
		method: 'POST',
		body: {
			paymentChannelRequest: {
				amount: {
					shippingCost: combinedShippingCost,
					items: combinedItemList,
					currency: combinedShippingCost.currency
				},
				payer: userProfileId,
				b2b: b2b
			}
		}
	})
	return data
}

export async function getPayment(csrfToken: string, paymentId: string) {
	const { error, data } = await useFetch('/api/neo/payment-service/getPayment', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			paymentId: paymentId
		}
	})
	return data
}

export async function getPaymentChannel(csrfToken: string, channelId: string) {
	const { error, data } = await useFetch('/api/neo/payment-service/getPaymentChannel', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		key: 'paymentChannel/id=' + channelId,
		method: 'POST',
		body: {
			channelId: channelId
		}
	})
	return data
}

export async function selectPaymentChannel(csrfToken: string, orderId: string, channelId: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/selectPaymentChannel', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			orderId: orderId,
			channelId: channelId
		}
	})
	return data
}

export async function killKlarna(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/killKlarna', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	return data
}

export async function getKlarnaSession(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/getKlarnaSession', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	return data
}

export async function getAllWertgarantieProductType(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/warranty-service/getAllWertgarantieProductType', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	return data
}

export async function finalizePendingOrder(csrfToken: string, orderId: string, urlSuccess: string, urlFailure: string, urlCancel: string, authorizationToken: string, captcha: string) {
	let body = {}
	body.orderId = orderId
	body.paymentRequest = {
		urlSuccess: urlSuccess,
		urlFailure: urlFailure,
		urlCancel: urlCancel,
	}
	body.finalizeOrigin = 'VUE'
	if (authorizationToken) body.paymentRequest.authorizationToken = authorizationToken
	if (captcha) body.captcha = captcha
	return await useFetch('/api/neo/frontend/_api/checkout/finalizePendingOrder', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: body
	})
}

export async function isAddressValid(csrfToken: string, address: object, orderId: string, isShippingTarget: boolean) {
	return await useFetch('/api/neo/frontend/_api/checkout/isAddressValid', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			address: address,
			orderId: orderId,
			isShippingTarget: isShippingTarget
		}
	})
}

export async function startKlarnaExpressOrderProcess(csrfToken: string, address: object, ppId: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/checkout/startKlarnaExpressOrderProcess', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			address: address,
			ppId: ppId
		}
	})
	return data
}

export async function getPaypalPaymentUrls(csrfToken: string, succesUrl: string, cancelUrl: string, failureUrl: string, orderId: string) {
	let body = {
		urls: {
			succesUrl: succesUrl,
			cancelUrl: cancelUrl,
			failureUrl: failureUrl
		}
	}
	body.orderId = orderId
	const { error, data } = await useFetch('/api/neo/paypal-checkout-service/getPaymentUrls', {
		method: 'POST',
		body: body
	})
	return data
}

export async function executePaypalPayment(csrfToken: string, paymentId: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/paypal-checkout/executePayment', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			paymentId: paymentId
		}
	})
	return data
}

export async function createPaypalExpressPayment(csrfToken: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/paypal-checkout/createExpressPayment', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
		}
	})
	return data
}

export async function continuePaypalPayment(csrfToken: string, paymentId: string) {
	const { error, data } = await useFetch('/api/neo/frontend/_api/paypal-checkout/continuePayment', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			paymentId: paymentId
		}
	})
	return data
}

async function getOrderStatus(csrfToken: string, orderId: string) {
	return await useFetch('/api/neo/frontend/_api/checkout/getOrderStatus', {
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': csrfToken
		},
		method: 'POST',
		body: {
			orderId: orderId
		}
	})
}

async function wait(time) {
	await new Promise(resolve => setTimeout(() => {
		resolve()
	}, time))
}

export async function orderApproved(csrfToken: string, orderId: string) {
	const { error, data } = await getOrderStatus(csrfToken, orderId)
	if (getRaw(data.value) != 'APPROVED') {
		await wait(1000)
		await orderApproved(csrfToken, orderId)
	}
}

export function getBinsTotal(bins) {
	let binSum = 0
	bins?.forEach(bin => {
		binSum += getItemsSum(bin?.itemList?.items, false) + bin.price?.gross
	})
	return binSum.toFixed(2)
}

export function getCartTotal(cart, unpromoted: boolean) {
	return getItemsSum(cart?.itemList, false, unpromoted)
}

export function getCartMwst(cart, unpromoted: boolean) {
	return getItemsSum(cart?.itemList, false) - getItemsSum(cart?.itemList, true, unpromoted)
}

function getItemsSum(items, isGetNet: boolean, unpromoted: boolean) {
	let itemsSum = 0
	items?.forEach(item => {
		let additionalItemsSum = 0
		item?.additionalItems?.items?.forEach(additionalItem => {
			additionalItemsSum += calcItemPrice(additionalItem, isGetNet, item.quantity)
		})
		itemsSum += calcItemPrice(item, isGetNet, item.quantity)
		itemsSum += additionalItemsSum
	})
	return itemsSum;
}

function calcItemPrice(item, isGetNet, quantity, unpromoted: boolean) {
	let promSum = 0
	if (!unpromoted) for (var key in item?.promotions) {
		promSum += (isGetNet ? item?.promotions[key].net : item?.promotions[key].gross)
	}
	return (isGetNet ? item?.price?.net : item?.price?.gross) * quantity + promSum * quantity
}

export function getRaw(rawData) {
	return isProxy(rawData) ? toRaw(rawData) : rawData;
}
