<template>
  <div>
    <LayoutHead v-if="!useAppConfig()?.isAppUser" />
    <LayoutAppHeader v-else />
    <main class="container mx-auto">
      <div class="p-4 grid py-6 text-sm lg:grid-cols-3" :class="useAppConfig()?.isAppUser ? 'pt-12' : ''">
        <div class="flex flex-col gap-4 col-span-2" v-if="useError()?.value?.statusCode == 429">
          <h2 class="category_heading !font-normal !text-2xl !pl-0 !pb-2">
            Das ging uns leider etwas zu schnell
          </h2>
          <p>Dies kann durch eine der folgenden Ursachen hevorgerufen worden sein:</p>
          <ul class="list-disc list-inside py-4">
            <li>Sie haben in übermenschlicher Geschwindigkeit Artikel aufgerufen</li>
            <li>Ihre IP-Addresse hat zuviele Aufrufe verursacht</li>
            <li>Unsere Systeme sind gerade überlastet</li>
          </ul>
          <p>
            Bitte versuchen Sie es in wenigen Minuten erneut.
          </p>
          <div class="mt-3">
            <NuxtLink v-if="timer > 0" class="returnButton inline-block text-white font-bold bg-secondary text-sm">
              Erneut versuchen in {{ timer }}s
            </NuxtLink>
            <NuxtLink v-else class="returnButton inline-block text-white font-bold bg-primary text-sm" @click="clearError();clearNuxtData();reloadNuxtApp();">
              Erneut versuchen
            </NuxtLink>
          </div>
        </div>
        <div class="flex flex-col gap-4 col-span-2" v-else>
          <h2 class="category_heading !font-normal !text-2xl !pl-0 !pb-2">
            Die angegebene Seite konnte leider nicht geladen werden
          </h2>
          <p>Dies kann durch eine der folgenden Ursachen hevorgerufen worden sein:</p>
          <ul class="list-disc list-inside py-4">
            <li>Sie haben einen fehlerhafte oder nicht mehr gültige URL aufgerufen</li>
            <li>Sie haben sich bei der Eingabe der URL auf diese Seiite vertippt</li>
            <li>Die Seite wurde verschoben</li>
            <li>Es ist ein unerwarteter Serverfehler aufgetreten</li>
          </ul>
          <p>
            Wenn Sie zur vorherigen Seite zurückkehren möchten, nutzen Sie bitte den
            "Zurück"-Button Ihres Browsers.
          </p>
          <div class="mt-3">
            <NuxtLink @click="clearNuxtData(); clearNuxtState();"
              class="returnButton inline-block text-white font-bold bg-primary text-sm"
              :to="useAppConfig()?.isAppUser ? '/app' : '/'" external>
              Zur Startseite >
            </NuxtLink>
          </div>
        </div>
        <div>
          <figure>
            <img class="float-right" alt="Keine Suchergebnisse" title="Keine Suchergebnisse"
              src="https://cdn.expert.de/cdn-cgi/image/format=auto,quality=50,compression=fast,fit=contain/ec/dd/48/6c3786c2a974aee320cd2d67a918524848/grafik_nulltrefferseite_100-0.jpg" />
          </figure>
        </div>
      </div>
    </main>
    <LayoutFooter v-if="!useAppConfig()?.isAppUser" />
    <LayoutAppFooter v-else />
  </div>
</template>
<script setup lang="ts">
provide(ProvideNames.BASE_DIR, "/shop/unsere-produkte/");
provide(
  "ImagePlaceHolder",
  "/17/89/06/2d7e8a948fb2646currentLayout51203723a2101d1283b/defaultImage_100x100_90-0.jpeg"
);
if (useError()?.value) {
  const res = { cause: { ...useError()?.value } }
  console.debug('found error', res);
  useNuxtApp()?.$faro.api.pushError(new Error('Fatal Error Caught', res));
}

const timer = ref(0);
let interval: any = undefined;

const route = useRoute()?.fullPath?.toLowerCase();
if (route?.startsWith('/app'))
  useAppConfig().isAppUser = true;
else if (useAppSettingsCookie()?.value?.isApp)
  useAppConfig().isAppUser = true;
onNuxtReady(() => {
  if (useError().value?.statusCode == 429) {
    timer.value = 60;
    interval = setInterval(() => {
      if (timer.value > 0)
        timer.value = timer.value - 1;
      else {
        // clearInterval(interval);
        //
      }
    }, 1000)
  }
  if (route.startsWith("/app")) {
    try {
      useNuxtApp()?.$openfeature.setContext({isAppUser: true});
      useAppSettingsCookie().value.isApp = true;
    } catch (error) {
      console.error("could not set context value", error);
    }
  }
});

onBeforeUnmount(() => {
  if (interval)
    clearInterval(interval);
})

</script>
<style>
.returnButton {
  text-align: center;
  padding: 8px 20px;

}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bubble {
  text-align: center;
  background-color: aliceblue;
}

.bubble-container {
  max-width: 1200px;
  max-height: 120px;
  margin: auto;
}

.store-logo {
  margin: auto;
}
</style>
