export const useCurrentWishList = () => useState('currentWishList');
export const useWishListItemCount = () => useState<number>('wishListItemsCount', () => 0);

const getAnonymousOrSystemUserId = () => {
	const user = useCurrentUserState()?.value;

	if (user && !user.roles?.includes('ANONYMOUS')) {
		return `systemuser_${user.userId}`;
	} else {
		return useExpCookieIdentifier().value;
	}
}

export const fetchCurrentWishList = async () => {
	const { data: wishlist } = await useFetch('/api/wishlist', {
		key: 'currentWishList',
		query: {
			userId: getAnonymousOrSystemUserId()
		}
	});
	useWishListItemCount().value = wishlist?.response?._data?.entries?.length;
	return wishlist;
};

const mergeWishlist = async () => {
	const systemUserId = `systemuser_${useCurrentUserState()?.value?.userId}`;
	const cookieUserId = useExpCookieIdentifier().value;

	const { data: systemUserWishlist } = await useFetch('/api/wishlist', {
		query: {
			userId: systemUserId
		}
	});

	if (systemUserWishlist.value && Array.isArray(systemUserWishlist.value?.entries)) {
		const { data: wishlist } = await useFetch('/api/wishlist/merge', {
			query: {
				systemUserId: systemUserId,
				cookieUserId: cookieUserId,
			},
		});

		return wishlist.value;
	}

	const { data: cookieUserWishlist } = await useFetch('/api/wishlist', {
		query: {
			userId: cookieUserId
		}
	});

	if (cookieUserWishlist.value && Array.isArray(cookieUserWishlist.value?.entries)) {
		for (const article of cookieUserWishlist.value?.entries) {
			await addArticleToWishList(useCurrentStore().value?.id, article.articleId, true);
		}
	}
}

export const getWishlist = async () => {
	const hasSystemUser: boolean = !useCurrentUserState()?.value?.roles?.includes('ANONYMOUS');

	let wishlist;
	if (hasSystemUser) {
		wishlist = await mergeWishlist();
	}

	if (!wishlist) {
		wishlist = await fetchCurrentWishList();
	}

	return wishlist;
};

const addArticleToWishList = async (storeid: string, articleId: string, registered: boolean) => {
	const userId = getAnonymousOrSystemUserId();

	return await useFetch('/api/wishlist/addArticle', {
		key: 'addArticleToWishList' + useExpCookieIdentifier()?.value,
		query: {
			storeId: storeid,
			userId: userId,
			articleId: articleId,
			registered: registered,
		},
		onResponse({ response }) {
			if (response.status >= 200 && response.status <= 400) {
				// might be somewhat short-sighted
				useWishListItemCount().value++;
				if (!useCurrentWishList()?.value?.entries) {
					useCurrentWishList().value = {
						entries: new Array(),
					};
				}
				useCurrentWishList()?.value?.entries?.push({ articleId: articleId, createdOn: Date.now() });
				if (useCurrentWishList().value.storeId == undefined) {
					useCurrentWishList().value.storeId = storeid;
				}
				useGeneralTrick({
					eventType: 'ADD_TO_CART_ITEM',
					sessionId: useCurrentSessionIdentifier()?.value,
					json: {
						cartType: 'WISHLIST_CART',
						articleId: articleId,
						cartId: useExpCookieIdentifier()?.value,
						quantity: 1,
					},
				});
			}
		},
	});
};

const deleteArticleFromWishList = async (articleId: string) => {
	const userId = getAnonymousOrSystemUserId()
	const { error } = await useFetch(useRuntimeConfig().public.apiUrl + '/api/wishlist/removeArticle', {
		key: 'deleteArticleFromWishList' + useExpCookieIdentifier()?.value,
		query: {
			userId: userId,
			articleId: articleId,
		},
		onResponse({ response }) {
			if (response.status == 200 || response.status == 404) {
				// might be somewhat short-sighted
				if (useWishListItemCount().value > 0) useWishListItemCount().value -= 1;
				useCurrentWishList().value.entries = useCurrentWishList().value?.entries?.filter((wItem) => wItem.articleId != articleId);
				useGeneralTrick({
					eventType: 'ADD_TO_CART_ITEM',
					sessionId: useCurrentSessionIdentifier()?.value,
					json: {
						cartType: 'WISHLIST_CART',
						articleId: articleId,
						cartId: useExpCookieIdentifier()?.value,
						quantity: 0,
					},
				});
			}
		}
	});
	if (error?.value) {
		throw new Error('Error removing from wishlist', { cause: { namespace: 'wishlist', errorCode: 2 } });
	}
}

export const updateStoreForWishList = (storeId: string) => {
	return useFetch('/api/wishlist/changeStore', {
		key: 'deleteArticleFromWishList' + useExpCookieIdentifier()?.value,
		query: {
			userId: getAnonymousOrSystemUserId(),
			storeId: storeId,
		},
	});
};

export const previousWishArt = () =>
	useState('previousWishArt', () => new Array().concat(useCurrentWishList()?.value?.entries?.map((wItem) => wItem.articleId)));

export const addToWishList = (articleId: string) => {
	let storeId = useCurrentStore().value?.id;
	if (previousWishArt()?.value?.includes(articleId) || useCurrentWishList()?.value?.entries?.find((wItem) => wItem.articleId == articleId)) {
		deleteArticleFromWishList(articleId);
		let index = previousWishArt()?.value?.indexOf(articleId);
		previousWishArt().value?.splice(index, 1);
	} else {
		document.getElementById(articleId + '-addingWishList');
		addArticleToWishList(storeId, articleId, true);
		previousWishArt()?.value?.push(articleId);
	}
}