import Client from '@searchkit/instantsearch-client';
export default defineNuxtPlugin(async (nuxtApp) => {
    let apiBase = useRuntimeConfig()?.public?.apiUrl || useRequestURL()?.href;
    return {
        provide: {
            searchClient: (path) => {
                let uri = new URL(apiBase);
                uri.pathname = path;
                // console.debug('returning searchClient for path ', path);
                return Client({
                    url: uri.href,
                    headers: computed(() => {
                        let headerObj;

                        headerObj = {
                            'x-emos-data': computed(() => {
                                const emos_jcvid = import.meta.client ? localStorage.getItem("emos_jcvid") : undefined || useCookie('emos-visitor').value;
                                const emos_jcsid = import.meta.client ? localStorage.getItem("emos_jcsid") : undefined || import.meta.client ? sessionStorage.getItem("emos_jcsid") : undefined || useCookie('emos-visitor').value;
                                const emos_privacy = import.meta.client ? localStorage.getItem("emos_privacy") : undefined
                                const emos_jckamp = useCookie("emos_jckamp").value || import.meta.client ? localStorage.getItem("emos_jckamp") : undefined;
                                return btoa(JSON.stringify({
                                    emosVid: emos_jcvid,
                                    emosSid: emos_jcsid,
                                    emosPrv: emos_privacy,
                                    emosKmp: emos_jckamp,
                                }))
                            }),
                            'x-bt-sessionIdentifier': computed(() => useCurrentSessionIdentifier()?.value),
                            'fmarktheader': computed(() => useCurrentStore()?.value?.id || useStoreCookie()?.value),
                        }
                        // }
                        return headerObj;
                    }).value
                });
            }
        }
    }
})