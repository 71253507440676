<template>
  <div class="store-logo-container">
    <BasicBtErrorBoundary>
      <nuxt-link :to="useAppConfig()?.homelink" :external="useAppConfig()?.homelinkExternal">
        <nuxt-img densities="x1" :data-storeid="useCurrentStore()?.value?.storeId" :src="image"
          class="store-logo transition" id="storeInformation" :alt="useCurrentStore()?.value?.name"
          :title="useCurrentStore()?.value?.name" :data-storename="useCurrentStore()?.value?.name"
          :data-storeFBPixel="useCurrentStore()?.value?.fbPixelId" provider="cloudflare">
        </nuxt-img>
      </nuxt-link>
    </BasicBtErrorBoundary>
  </div>
</template>
<script setup lang="ts">

const image = ref("47/f1/37/344581753cb59b3dfbe24d781b76f70899/expert_logo.svg");

if (useFeatureState('cfg-blackweek-header')?.value === true) {
  image.value = 'a0/d1/90/7ad964598b9b63833e1a4fa34bd0c2aa98/logo_2879130.svg';
}

onNuxtReady(() => {
  watchEffect(() => {
    watch(useCurrentStore(), () => {
      if (useStoreCookie()?.value == useCurrentStore()?.value?.id && useCurrentStore()?.value?.storeLogoImg != undefined && !useRoute().fullPath?.toLowerCase().includes('campaignid')) {
        image.value = useCurrentStore()?.value?.storeLogoImg;
      }
      if (useFeatureState('cfg-blackweek-header')?.value == true && useCurrentStore()?.value?.companyGroupId != '1906674') {
        if (useStoreCookie()?.value == useCurrentStore()?.value?.id && useCurrentStore()?.value?.storeLogoDarkImg && !useRoute().fullPath?.toLowerCase().includes('campaignid')) {
          image.value = useCurrentStore()?.value?.storeLogoDarkImg;
        }
      }
      if (!useAppConfig()?.isAppUser) {
        useAppConfig().homelink = '/' + useCurrentStore()?.value?.storeLink || '/';
        useAppConfig().homelinkExternal = true;
      }

    }, { immediate: true });
  })
})

</script>