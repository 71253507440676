<template>
    <div>
        <div class="promo-bubble" v-if="imgSrc != undefined && toggleBubble" @click="opacity = !opacity">
            <NuxtImg :src="imgSrc" preset="promo_big" :alt="filtered.alt" :style="{ opacity: opacity ? 1 : 0.1 }">
            </NuxtImg>
        </div>
    </div>
</template>
<script setup lang="ts">

const props = defineProps({
    articleId: {
        type: String
    },
    price: {
        type: Object,
        watch: true
    },
    listIndex: {
        type: Number,
        default: 0
    }
});

const opacity = ref(true);
const allActive = useActivePromotions();

await allActive.value;
if (allActive?.value == undefined) {
    allActive.value = new Array();
}

const imgSrc = ref();
const toggleBubble = ref(true);
let filtered;
let firstItem;

const isFreeShipping = () => {
    return (props?.price?.shipmentArray && props?.price?.shipmentArray?.[0]?.shipmentBruttoPrice === 0 ? true : false);
}

const removeBubblesByAvailablility = (promotions) => {
    const onlineAvailabilityArray = ['AVAILABLE', 'NEXT_DAYS_AVAILABLE', 'PREORDER', 'NOT_IN_STORE']
    const storeAvailabilityArray = ['AVAILABLE', 'ONLY_IN_STORE']
    return promotions.filter(prom => 
        (prom.orderModification.find(pom => pom.showBubbleOnlyWhenOnlineAvailable) == undefined || onlineAvailabilityArray.includes(props?.price?.onlineAvailability))
     && (prom.orderModification.find(pom => pom.showBubbleOnlyWhenStoreAvailable) == undefined || storeAvailabilityArray.includes(props?.price?.storeAvailability))
    ).filter(prom => 
        prom.orderModification.find(pom => !pom.stores || !pom.stores.length || pom.stores.indexOf(useCurrentStore()?.value?.id) > -1) != undefined
    )
}

watchEffect(() => {
    let filteredList = removeBubblesByAvailablility(allActive?.value)
    filteredList = filteredList?.filter(prom => prom.orderModification.find(pom => (pom.bubbleArticles == undefined && pom.affectedArticles?.includes(props?.articleId)) || pom.bubbleArticles?.includes(props?.articleId) || pom.affectedArticles?.includes('*') || pom.bubbleArticles?.includes('*')) != undefined && prom?.teaser?.originalData != undefined).sort((a, b) => { a.priority - b.priority })
    firstItem = filteredList.at(0)
    filtered = filteredList.at(props.listIndex)
    // console.log('[bt-bubble] active ones', allActive);
    // console.debug('[bt-bubble] filtered ones', filtered);

    if(firstItem?.teaser?.originalData == undefined && isFreeShipping() && props.listIndex > 0) {
        toggleBubble.value = false
    }

    if (filtered?.teaser?.originalData == undefined && isFreeShipping()) {
        // console.debug('adding VSK Promo');
        filtered = {
            id: 'VSK',
            teaser: { originalData: 'ae/e2/19/7c4fee3cfa70e4d1a0ab3ed075c1871a41/Versandkostenfrei_FMS_1000x1000.png' },
            alt: 'Versandkostenfrei',
            title: 'Versandkostenfrei'
        }
    }
    imgSrc.value = filtered?.teaser?.originalData;
})

</script>
<style>
.promo-bubble {
    transition: transform 0.2s;
    max-width: 60px;
    max-height: 60px;
}

.promo-bubble:hover {
    transform: scale(2);
    opacity: 1;
}
</style>
